import {get} from 'lodash';

export default (data) => {

  const websiteValue = get(data, 'acf_dealers.website', null);
  const openingHoursValue = get(data, 'acf_dealers.openingHours', null);

  const latValue = get(data, 'acf_dealers.latitude', null);
  const lngValue = get(data, 'acf_dealers.longitude', null);
  const location = (latValue && lngValue) ? {
    lat: latValue ? parseFloat(latValue) : null,
    lng: lngValue ? parseFloat(lngValue) : null
  } : null

  const email = get(data, 'acf_dealers.mainEmail', null);
  const encodedEmail = email ? email.split('@') : null

  const contact = {
    addressLine1: get(data, 'acf_dealers.addressLine1', null),
    addressLine2: get(data, 'acf_dealers.addressLine2', null),
    phone: get(data, 'acf_dealers.phone', null),
    website: websiteValue ? (websiteValue.indexOf('Not supplied') !== -1) ? null : (websiteValue.indexOf('http') === -1 ? `http://${websiteValue}`: websiteValue) : null,
    openingHours: openingHoursValue ? openingHoursValue : null,
    openingHoursFormatted: openingHoursValue ? openingHoursValue.split('|').join(',<br />') : null,
    email: encodedEmail,
    suburb: get(data, 'acf_dealers.suburb', null),
    state: get(data, 'acf_dealers.state', ''),
    postcode: get(data, 'acf_dealers.postcode', ''),
  }

  let addressArr = [];
  contact.addressLine1 && addressArr.push(contact.addressLine1);
  contact.addressLine2 && addressArr.push(contact.addressLine2);
  contact.suburb && addressArr.push(contact.suburb);

  if (contact.state && contact.postcode) {
    addressArr.push(`${contact.state} ${contact.postcode}`)
  }

  if (contact.state && !contact.postcode) {
    addressArr.push(`${contact.state}`)
  }

  if (!contact.state && contact.postcode) {
    addressArr.push(`${contact.postcode}`)
  }

  const formattedAddress = addressArr.join(', ')

  return {
    text: get(data, 'acf_dealers.dealerAboutUs', null),
    contact: {
      ...contact,
      formattedAddress
    },
    sales: get(data, 'acf_dealers.sales', '') === '1' ? true : false,
    service: get(data, 'acf_dealers.service', '') === '1' ? true : false,
    location
  }
}